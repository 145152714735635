<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <div class="background">
    <el-container>
      <el-aside width="3rem" height="100%">
        <div style="height: 100%;background-color: #3C3E3E">
          <el-menu
              :uniqueOpened="true"
              background-color="#3C3E3E"
              text-color="#ffffff"
              active-text-color="#ffffff"
              class="menu"
              :collapse-transition="false"
              :router="true">
            <el-menu-item-group>
              <div class="companyLogo" @click="this.$router.push('/Home')">

              </div>
            </el-menu-item-group>

            <el-submenu index="2">
              <template #title>
                <div  style="text-align: center; font-size: .25rem; font-weight: 500">项目管理</div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/AllProjects"><div style="text-align: center;font-size: .2rem; font-weight: 400">所有项目</div></el-menu-item>
                <el-menu-item index="/AuditingProjects"><div style="text-align: center;font-size: .2rem; font-weight: 400">审核项目</div></el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="3">
              <template #title>
                <div  style="text-align: center; font-size: .25rem; font-weight: 500">案例管理</div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/AllCases"><div style="text-align: center;font-size: .2rem; font-weight: 400">全部案例</div></el-menu-item>
                <el-menu-item index="/CaseAdd"><div style="text-align: center;font-size: .2rem; font-weight: 400">添加案例</div></el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template #title>
                <div  style="text-align: center; font-size: .25rem; font-weight: 500">供应商管理</div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/AllSupply"><div style="text-align: center;font-size: .2rem; font-weight: 400">全部供应商</div></el-menu-item>
                <el-menu-item index="/AddSupply"><div style="text-align: center;font-size: .2rem; font-weight: 400">添加供应商</div></el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="5">
              <template #title>
                <div  style="text-align: center; font-size: .25rem; font-weight: 500">认证审核</div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/404"><div style="text-align: center;font-size: .2rem; font-weight: 400">审核需求</div></el-menu-item>
                <el-menu-item index="/AllCertificate"><div style="text-align: center;font-size: .2rem; font-weight: 400">企业认证</div></el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="7">
              <template #title>
                <div  style="text-align: center; font-size: .25rem; font-weight: 500">平台管理</div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/HeadPicManage"><div style="text-align: center;font-size: .2rem; font-weight: 400">页面资源管理</div></el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="8">
              <template #title>
                <div  style="text-align: center; font-size: .25rem; font-weight: 500">合同管理</div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/ContractUpload"><div style="text-align: center;font-size: .2rem; font-weight: 400">合同模版</div></el-menu-item>
                <el-menu-item index="/ContractAuditing"><div style="text-align: center;font-size: .2rem; font-weight: 400">合同审核</div></el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="9">
              <template #title>
                <div  style="text-align: center; font-size: .25rem; font-weight: 500">活动管理</div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/ActivityManage"><div style="text-align: center;font-size: .2rem; font-weight: 400">活动管理</div></el-menu-item>
                <el-menu-item index="/SignList"><div style="text-align: center;font-size: .2rem; font-weight: 400">申请管理</div></el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <el-header width="16.2rem" height=".9rem" style="--el-header-padding: 0">
          <div class="header">
            <div class="header_left">
              <i style="font-size:.24rem" class="fa">&#xf0c9</i>
              <p>工作台</p>
            </div>
            <div class="header_right">
              <i class="fa fa-envelope-o" style="font-size:.24rem;"></i>
              <div class="user_tx"></div>
              <div class="user_name">管理员</div>
              <i class="fa fa-angle-down" style="font-size:.24rem"></i>
            </div>
          </div>
        </el-header>
        <el-main>
            <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "AdministerBackHome",
}
</script>

<style scoped>
.background{
  float: left;
  height: 11.94rem;
  width: 19.2rem;
}

.header{
  float: left;
  width: 16.22rem;
  height: .9rem;
  background-color: #ffffff;
  border: .01rem solid transparent;
}

.header .header_left{
  float: left;
  width: 1.24rem;
  height: .24rem;
  margin-left: .33rem;
  margin-right: 11.4rem;
  margin-top: .4rem;
}

.header .header_left i{
  display: inline-block;
  width: .24rem;
  height: .24rem;
  margin-right: .4rem;
}

.header .header_left p{
  display: inline-block;
  width: .6rem;
  height: .24rem;
  font-size: .2rem;
  color:#212121;
}

.header .header_right{
  float: right;
  width: 2.22rem;
  height: .5rem;
  line-height: .5rem;
  margin-top: .25rem;
}

.header .header_right .fa-envelope-o{
  display: block;
  float: left;
  margin: .13rem .2rem 0 0;
  /*margin-right: .2rem;*/
}

.header .header_right .fa-angle-down{
  display: block;
  float: left;
  margin: .13rem 0 .13rem 0;
}

.header .header_right .user_tx{
  float: left;
  width: .5rem;
  height: .5rem;
  margin-right: .1rem;
  border-radius: 50%;
  background-image: url("../../../images/tx.png");
  background-size: 100% 100%;
}

.header .header_right .user_name{
  height: .5rem;
  line-height: .5rem;
  float: left;
  font-size: .2rem;
  margin-right: .1rem;
  color: #212121;
}




.companyLogo{
  width: 3rem;
  height: 1rem;
  background: url("../../../images/head_logo_w.png") no-repeat center;
  background-size: 70%;
  cursor: pointer;
}

.el-menu-item{
  text-align: center;
  font-size: .16rem;
  font-weight: 300;
}

.test {
  width: 16.22rem;
}

.el-menu-item{
  text-align: center;
  font-size: .16rem;
  font-weight: 300;
}

.test {
  width: 16.22rem;
}


</style>
